import { menuGroups, type MenuGroup } from "~/constants/menu";

export const useMenu = () => {
  const route = useRoute();
  const nuxtApp = useNuxtApp();

  /**
   * Retorna todos os items de um grupo permitidos para o usuário
   */
  const getAllowedGroupItems = (group: MenuGroup) =>
    group.items.filter((item) => {
      if (item.roles || item.actions) {
        if (
          !nuxtApp.$auth.checkUserAccess({
            roles: item.roles,
            actions: item.actions,
          })
        ) {
          return false;
        }
      }
      return true;
    });

  /**
   * Filtra todos os grupos permitidos para o usuário
   */
  const allowedGroupsForUser = computed<MenuGroup[]>(() => {
    return menuGroups
      .filter((group) => {
        // Caso o grupo tenha roles permitidas
        if (
          group.roles &&
          !nuxtApp.$auth.checkUserAccess({
            roles: group.roles,
          }) === false
        ) {
          return false;
        }

        // Caso não tenha nenhum item permitido no grupo, exclui o grupo
        const groupItems = getAllowedGroupItems(group);
        if (!groupItems.length) return false;

        return true;
      })
      .map((group) => ({
        ...group,
        items: getAllowedGroupItems(group),
      }));
  });

  const firstAvailableRoute = computed(() => {
    if (allowedGroupsForUser.value.length) {
      if (
        allowedGroupsForUser.value[0].items &&
        allowedGroupsForUser.value[0].items.length
      ) {
        return allowedGroupsForUser.value[0].items[0];
      }
    }
    return undefined;
  });

  return {
    allowedGroupsForUser,
    firstAvailableRoute,
  };
};
